import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const OnlineKreditrechner = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Online-Kreditrechner" showCalc={false} />
            <Article>
                <h2>Online-Kreditrechner – Ja oder nein?</h2>
                <p>
                    Du hast dir einen Überblick über deine Finanzen verschafft, kennst Möglichkeiten und Grenzen deines
                    Budgets und bist – die Traumimmobilie stets vor Augen – nun auf der Suche nach einem möglichst
                    günstigen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    . Doch wie findest du eigentlich das beste Angebot zur Finanzierung deines neuen Eigenheims? Eine
                    erste Orientierungshilfe können{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Online-Kreditrechner
                    </Link>{" "}
                    bieten. Was sie leisten und was du bei ihrer Verwendung beachten musst? In diesem Beitrag erfährst
                    du es.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Online-Kreditrechner: gut für die Ersteinschätzung.</h2>
                <p>
                    Eines gleich vorweg: Wie eingangs bereits erwähnt, stellen online verfügbare{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    lediglich eine erste Etappe in Richtung eines günstigen Immobilienkredits dar. Das ist deswegen der
                    Fall, weil du hier nur standardisierte Angebote vergleichen kannst, ohne die Grenzkonditionen der
                    jeweiligen Banken zu kennen, bei denen du deinen Kredit in einem weiteren Schritt beantragst.
                    Insbesondere deine Hausbank wird dir zwar Nachlässe bei einmalig fälligen Gebühren zugestehen,
                    jedoch kaum bei den konkreten Konditionen des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>
                    . Es gilt daher, eine Bank zu finden, deren Geldpolitik offensiv genug ist, um eine möglichst
                    günstige Verhandlungsbasis zu schaffen.
                </p>
                <hr />

                <h2>Was sollte ein Kreditrechner können?</h2>
                <p>
                    Dennoch kann ein{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    wertvolle Informationen bieten, die eine fundierte Beurteilung vonKreditangeboten ermöglichen. Dabei
                    ist es wichtig, dass der Rechner hinsichtlich seiner Bedienung transparent und effizient ist – du
                    solltest mit einer relativ geringen Anzahl an Eingaben zu den gewünschten Ergebnissen gelangen.
                    Darüber hinaus sollte er die Richtlinien desHypothekar- und Immobilienkreditgesetzes
                    berücksichtigen. Dieses legt u.a. Parameter fest, die jeder seriöse{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    anzugeben hat. Dazu zählen insbesondere
                </p>
                <ul>
                    <li>Soll- und Effektivzinssatz</li>
                    <li>Laufzeit</li>
                    <li>Monatliche Rate</li>
                    <li>Zinsmodell</li>
                    <li>Gesamtbelastung (Kreditbetrag inkl. Zinsen und sämtlicher Nebenkosten)</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kreditrechner: die richtige Vorbereitung fürs Beratungsgespräch</h2>
                <p>
                    Neben der Verwendung eines Kreditrechners ist es empfehlenswert, sich durch eine möglichst
                    genaueHaushaltsrechnung in eine aussichtsreiche Position für die Beratungsgespräche mit den infrage
                    kommenden Banken zu bringen. Eine Haushaltsrechnung ist denkbar einfach und dennoch ein wichtiges
                    finanzielles Instrument, das dabei hilft schnell und einfach eine ideale monatliche Kreditrate sowie
                    eine maximale Obergrenze zu definieren, indem dasfrei verfügbare bzw.zumutbare Einkommen ermittelt
                    wird. Um dieses zu bestimmen, berechnest du die Differenz zwischen Einnahmen und Ausgabe auf den
                    Gehaltskontoauszügen der vergangenen drei Monate. Ausgehend von diesem Ergebnis kannst du dann
                    sowohl eine Summe benennen, die für dich problemlos leistbar ist, als auch einen Maximalwert, bis zu
                    dem du der Bank gegebenenfalls entgegenkommen würdest.
                </p>
                <p>
                    Um im Falle steigender{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    über genügend Kapital für die ebenfalls erhöhte Ratenbelastung zu verfügen, solltest du hierbei
                    allerdings eineSicherheitsreserve von zumindest 20 % einplanen. Übrigens: Auch bei den
                    Vertreter*innen der Banken wird es einen äußerst positiven Eindruck hinterlassen, wenn du in einem
                    Beratungsgespräch eine exakte Haushaltsrechnung vorlegen kannst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Probier es jetzt aus, mit dem Online-Kreditrechner von miracl.</h2>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />
            <Article>
                <h2>Exkurs: Wie finde ich einen attraktiven Kredit?</h2>
                <p>
                    Ein{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Online-Kreditrechner
                    </Link>{" "}
                    Doch um den passenden Kredit zu finden, sind natürlich noch weitere Aspekte von entscheidender
                    Bedeutung:
                </p>
                <ul>
                    <li>eine leistbare Monatsbelastung, je nach Höhe des monatlichen Einkommens</li>
                    <li>umfangreiche Laufzeit, denn je länger die Laufzeit desto kleiner die Rate</li>
                    <li>Rückzahlungsmö glichkeiten, die sich flexibel gestalten lassen</li>
                    <li>angemessene Sicherheiten, damit du dein Risiko klein halten kannst</li>
                    <li>
                        günstigster Zins – gemessen an der eigenen{" "}
                        <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                            Bonität
                        </Link>
                    </li>
                </ul>
                <p>
                    Die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    , die im Rahmen eines Kredites fällig werden, sind von mehreren Variablen abhängig. Zunächst ist
                    derReferenzzinssatz zu berücksichtigen – der Zins, dessen Erträge Banken für ihre Tätigkeiten am
                    Finanz- bzw. Kapitalmarkt einsetzen. Hinzu kommt ein Aufschlag, dessen Höhe sowohl von deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    als auch von der Bonität der Bank abhängt – die sogenannteMarge. Von deiner individuellen
                    Risikobereitschaft sowie dem augenblicklichen Zinsniveau hängt es schließlich ab, ob du dich für
                    eineFixzinsbindung oder fürvariable{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    entscheidest.
                </p>
                <p>
                    Ist das Zinsniveau aktuell niedrig, so kann es empfehlenswert sein, einen Fixzinskredit zu
                    beantragen. Dieser bietet dir über seine gesamte Laufzeit hinweg die größtmögliche Sicherheit
                    hinsichtlich der Planung. Zugleich musst du dir jedoch darüber im Klaren sein, dass ein fixer
                    Zinssatz auch von einem potenziell sinkenden Zinsniveau am Finanzmarkt unberührt bleibt. Rechnest du
                    also damit, dass das Zinsniveau während der Laufzeit deines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    konstant bleiben oder sogar sinken wird, dann solltest du eine variable Verzinsung in Erwägung
                    ziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"onlineKreditrechner"}></BreadcrumbList>
            <ArticleStructuredData page={"onlineKreditrechner"} heading={"Online-Kreditrechner"} />
        </Layout>
    );
};

export default OnlineKreditrechner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.online-kreditrechner", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
